.App {
  text-align: center;
}

.container {
  display: flex;
  height: fit-content;
  min-height: 100dvh;

}

.side {
  background-color: lightblue;
  min-width: 0;
  flex:1;
  height: fit-content;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
}

.middle {
  background-color: #36454F;
  min-width: 300px;
  max-width: 700px;
  width: 100%;
  flex: 0 1 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  min-height: 100dvh;
}

.middle-loading {
  background-color: #36454F;
  min-width: 300px;
  max-width: 700px;
  width: 100%;
  flex: 0 1 auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  width: 100%;
  /* height: 40vmin; */
  pointer-events: none;
  /* padding-bottom: 50px; */
}

.App-logo-form {
  width: 100%;
  /* height: 40vmin; */
  pointer-events: none;
  /* padding-bottom: 50px; */
}

.text-padding {
  padding-bottom: 10px;
}

.input-label {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  text-align: left;
  /* background-color: white; */
}

.button-label {
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}
/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
